<template>
  <div class="companyInfo pt-r">
    <!-- // isauthentication 企业是否实名 1实名 -->
    <!-- userInfo.sysCompany.isauthentication==1 -->
    <div class="companyInfo-title">企业信息</div>
    <div class="mt-50 mr-14 side">
      <!-- 已认证 -->
      <template v-if="userInfo.sysCompany">
        <div class="headportrait">
          <img class="img br50" v-if="userInfo.sysCompany.signInformationDefinition&&userInfo.sysCompany.signInformationDefinition.logoUrl" 
          :src="userInfo.sysCompany.signInformationDefinition.logoUrl" />
          <img class="img br50" v-else :src="require('@/assets/img/general/enterprise.png')" />
        </div>
        <div class="ml-14" >
          <div class="name fw-b fs-22">{{userInfo.sysCompany.companyName}}</div>
          <div class="mt-14 side fw-w">
            <realname class="mr-8" is="true" CSStype="3" type="2" v-if="userInfo.sysCompany.isauthentication==1"></realname>
            <realname class="mr-8" CSStype="3" type="2" v-else></realname>
            <template  v-if="userInfo.sysCompany">
              <div class="role fs-12" v-for="(el,ind) in userInfo.sysCompany.roleList" :key="ind">
                {{el.roleName}}
              </div>
            </template>
          </div>
        </div>
      </template>
      <!-- 未认证 -->
      <div class="fs-14 c-666" v-else>
        <p class="mb-18">当前未进行企业认证，前往认证解锁更多企业权益</p>
        <oabutton title="前往认证" CSStype=2 width=92 height=32 @buttonclick="certifiedNewEnterprise"></oabutton>
      </div>
    </div>
    <div class="rightup cu-p">
      <el-popover :visible="visible" placement="bottom" :width="340" trigger="click" popper-class="el_popper_none_padding">
          <switchCenter :type="2" @newCompany="clickUserCheck(() => { unref(startRef).add() })"  ref="switchref"></switchCenter>
          <template #reference>
            <div class="dp-f">
              <el-image :src="require('@/assets/img/home/cut.png')" />
            <span class="ml-7">切换企业</span>
            </div>
          </template>
      </el-popover>
    </div>
    <el-image class="rightdown" :src="require('@/assets/img/home/bitmap.png')"></el-image>
      <start ref="startRef" @Emit="unref(switchref).getCompanyall(), resetUserInfo(() => { })"></start>
  </div>
</template>

<script setup>
import start from "@/views/user/components/userModule/myCompany/start.vue"; //创建新企业
import { router_push_name } from "@/utils/server/router";
import { handleMessage } from "@/utils/server/confirm.js"
import { clickUserCheck } from "@/utils/base/realname.js"
import { ref, unref, reactive, computed } from "vue";
import { useStore } from "vuex";
import realname from "@/components/icon/realname.vue"
import switchCenter from "@/components/dialog/switch.vue"
const startRef = ref();
const switchref=ref()
const store = useStore();
// isCertification 个人实名认证状态 0是未认证 1是已认证
const userInfo =JSON.parse(window.sessionStorage.user).user_info
// 认证新企业点击
const certifiedNewEnterprise=((companyId)=>{
  if(userInfo.isCertification==0){
    handleMessage('请先完成个人实名认证')
    return
  }
  store.commit('realName/resetData') 
  store.commit('realName/setType', 2)
  if(companyId){
    store.commit('realName/setCompanyId', companyId)
  }
  router_push_name('realname_boot')
})
</script>

<style lang="scss" scoped>
.companyInfo {
  width:  calc(50% - 6px) ;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(40, 144, 255, 0.11) 0%,
    #fff 100%
  );
  // rgba(40, 144, 255, 0) 100%
  padding: 20px;
  padding-left: 30px;
  &-title{
    font-size: 20px;
    font-weight: 600;
  }
  .headportrait {
    // border: 1px solid #000;
    border-radius: 50%;
    width: 71px;
    height: 71px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .side {
    display: flex;
    align-content: space-between;
  }
  .approve {
    padding: 3px 8px;
    background-color: rgba(24, 144, 255, 0.2);
    border-radius: 4px;
    color: #2890FF;
    display: flex;
    align-items: center;
  }
  .role {
    padding: 3px 8px;
    color: var(--active-theme);
    background-color: var(--active-alpha);
    border-radius: 4px;
    margin: 0 5px 5px 0px;
  }
  .rightup {
    position:absolute;
    top:26px;
    right: 30px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .rightdown {
    position:absolute;
    right: 0;
    bottom: 0;
    width: 243px;
    height: 159px;
  }
}
</style>